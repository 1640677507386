import React from 'react';
import axios from 'axios';
import DogElement from 'components/Molecules/DogElement/DogElement';
import styled from 'styled-components';
import Title from 'components/Atoms/Title/Title';
import cookies from 'react-cookies';
import Loading from 'components/Atoms/Loading/Loading';

const BreedContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 10px auto 20px auto;
    flex-wrap: wrap;
`;

const Container = styled.div`
    width: 100%;
    text-align: center;
`;

const Dogs = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    height: 100%;
`;

export default class GetDogsElements extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            items: [],
        };
    }

    componentDidMount() {
        axios
            .get('https://api.shadowdog.eu/v1/dogs')
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { isLoading, error, items } = this.state;
        const language = cookies.load('language');

        if (error) {
            return <span>Błąd: {error}</span>;
        } else if (!isLoading) {
            return <Loading />;
        } else {
            return (
                <BreedContainer>
                    <Container>
                        <Dogs>
                            {items
                                .filter((element) => element.breed === 'IRWS')
                                .map((dog) => (
                                    <DogElement
                                        key={dog.id}
                                        id={dog.id}
                                        title={
                                            dog.gender === 'female'
                                                ? '♀ ' + dog.name
                                                : '♂ ' + dog.name
                                        }
                                        gender={dog.gender}
                                        breed={dog.breed}
                                        content={
                                            language === 'PL'
                                                ? dog.pl_description
                                                : dog.en_description
                                        }
                                        results={
                                            language === 'PL'
                                                ? dog.pl_results
                                                : dog.en_results
                                        }
                                        gallery={
                                            dog.gallery.length
                                                ? dog.gallery
                                                : null
                                        }
                                    />
                                ))}
                        </Dogs>
                    </Container>
                    <Container>
                        <Dogs>
                            {items
                                .filter((element) => element.breed === 'ES')
                                .map((dog) => (
                                    <DogElement
                                        key={dog.id}
                                        id={dog.id}
                                        title={
                                            dog.gender === 'female'
                                                ? '♀ ' + dog.name
                                                : '♂ ' + dog.name
                                        }
                                        gender={dog.gender}
                                        breed={dog.breed}
                                        content={
                                            language === 'PL'
                                                ? dog.pl_description
                                                : dog.en_description
                                        }
                                        results={
                                            language === 'PL'
                                                ? dog.pl_results
                                                : dog.en_results
                                        }
                                        gallery={
                                            dog.gallery.length
                                                ? dog.gallery
                                                : null
                                        }
                                    />
                                ))}
                        </Dogs>
                    </Container>
                    <Container>
                        <Title>
                            {language === 'pl'
                                ? 'W naszej pamięci'
                                : 'In our memory'}
                        </Title>
                        <Dogs>
                            {items
                                .filter((element) => element.breed === 'MEMORY')
                                .map((dog) => (
                                    <DogElement
                                        key={dog.id}
                                        id={dog.id}
                                        title={
                                            dog.gender === 'female'
                                                ? '♀ ' + dog.name
                                                : '♂ ' + dog.name
                                        }
                                        gender={dog.gender}
                                        breed={dog.breed}
                                        content={
                                            language === 'PL'
                                                ? dog.pl_description
                                                : dog.en_description
                                        }
                                        results={
                                            language === 'PL'
                                                ? dog.pl_results
                                                : dog.en_results
                                        }
                                        gallery={
                                            dog.gallery.length
                                                ? dog.gallery
                                                : null
                                        }
                                    />
                                ))}
                        </Dogs>
                    </Container>
                </BreedContainer>
            );
        }
    }
}
