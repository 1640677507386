import React from 'react';
import LogoIMG from 'assets/images/logo.png';
import styles from './Logo.module.scss';

const Logo = ({ width, height, alt }) => (
    <img
        src={LogoIMG}
        alt={alt || 'Logo ShadowDog'}
        width={width || '75px'}
        height={height || '75px'}
        className={styles.Logo}
    />
);

export default Logo;
