import React from "react";
import cookies from 'react-cookies';
import Title from 'components/Atoms/Title/Title';
import MapLocation from 'components/Atoms/MapLocation/MapLocation';
import ContactBar from 'components/Molecules/ContactBar/ContactBar';

const language = cookies.load('language');

const ContactView = (props) => (
    <>
        <Title size="50px">{language === "pl" ? "Kontakt" : "Contact"}</Title>

        <ContactBar />

        <MapLocation />
    </>
);


export default ContactView;
