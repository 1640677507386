import Title from 'components/Atoms/Title/Title';
import React from 'react';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { Markup } from 'interweave';
import cookies from 'react-cookies';

const language = cookies.load('language');

const AboutusElement = ( { title, content, gallery } ) => (
    <div className="w-3/4 mt-4 text-base m-auto bg-black bg-opacity-10 rounded-xl px-9 py-5">
        <div className="block w-1/1">
            <Title size="35px" align="left">{title}</Title>
        </div>
        <div className=" w-1/1 text-justify m-auto mt-4 text-gray-400">
            <Markup content={content} />
        </div>
        <div className="w-11/12 mx-auto flex flex-row flex-wrap justify-center items-center py-4">
            <SimpleReactLightbox>
                <SRLWrapper>
                    <div className="w-1/1 mx-auto flex flex-row flex-wrap justify-center items-center">
                        {gallery.map((item, index) => (
                            <a href={item.pathurl}>
                                <img id={index} src={item.pathurl} alt={item.text} className=" h-28 w-auto m-2 rounded-lg filter hover:brightness-125 transition duration-75 ease-in-out" />
                            </a>
                        ))}
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>
        </div>
    </div>
)

export default AboutusElement;
