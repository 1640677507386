import React, { Component } from 'react';
import male from 'assets/images/male.svg';
import female from 'assets/images/female.svg';
import Title from 'components/Atoms/Title/Title';
import Modal from 'components/Molecules/Modal/Modal';
import Pedigree from 'components/Molecules/Pedigree/Pedigree';
import axios from 'axios';
import cookies from 'react-cookies';

export default class DogsDetailsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            items: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        // /dogs/
        const id = window.location.pathname.slice(6);

        axios
            .get(`https://api.shadowdog.eu/v1/dogs/${id}`)
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { error, items, isLoading } = this.state;
        const language = cookies.load('language');

        if (error) {
            return <span>Error: {error.message}</span>;
        } else if (!isLoading) {
            return <span>Loading...</span>;
        } else {
            return (
                <div className='min-h-page pb-5'>
                    {/* TITLE */}
                    <div className='w-4/5 flex justify-center items-center m-auto'>
                        {items.gender === 'male' ? (
                            <img
                                className='w-8 h-8 mr-3'
                                src={male}
                                alt='Male'
                            />
                        ) : (
                            <img
                                className='w-8 h-8 mr-3'
                                src={female}
                                alt='Female'
                            />
                        )}
                        <Title size='35px'>{items.name}</Title>
                    </div>

                    {/* DOG DESCRIPTION */}
                    <div className='flex xl:flex-row flex-col-reverse justify-center items-center w-3/4 my-5 mx-auto text-gray-600'>
                        <div className='w-1/1 xl:w-1/2 break-words text-center p-5 mx-auto'>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        language === 'pl'
                                            ? items.pl_description
                                            : items.en_description,
                                }}
                            />
                        </div>
                        <div className='w-1/1 xl:w-1/2 flex flex-row mb-3 mx-auto'>
                            <img
                                src={items.gallery
                                    .filter((e) => e.category === 'dogsImg')
                                    .map((item) => item.pathurl)}
                                alt=''
                                className='flex items-end rounded-xl max-w-full mx-auto'
                            />
                        </div>
                    </div>

                    {/* MODAL BUTTONS */}
                    <div className='w-1/2 flex flex-row justify-around items-center my-5 mx-auto py-6'>
                        <Modal
                            title={language === 'pl' ? 'Galeria' : 'Gallery'}
                            content={items.gallery}
                        />
                        <Modal
                            title={
                                language === 'pl' ? 'Wystawy' : 'Show Results'
                            }
                            content={
                                language === 'pl'
                                    ? items.pl_achievements
                                    : items.en_achievements
                            }
                        />
                        {items.gender === 'male' ? (
                            <Modal
                                title={
                                    language === 'pl'
                                        ? 'Potomstwo'
                                        : 'Offspring'
                                }
                                content={
                                    language === 'pl'
                                        ? items.pl_offspring
                                        : items.en_offspring
                                }
                            />
                        ) : (
                            ''
                        )}
                    </div>

                    {/* PEDIGREE */}
                    <Pedigree
                        content={items.gallery.filter(
                            (e) => e.category === 'pedigree'
                        )}
                    />
                </div>
            );
        }
    }
}
