import React from 'react';
import Title from 'components/Atoms/Title/Title';
import GetLittersElements from 'components/Organisms/GetLittersElements/GetLittersElements';
import NewsElementGlobal from 'components/Molecules/NewsElementGlobal/NewsElementGlobal';
import cookies from 'react-cookies';

const language = cookies.load('language');

const LittersView = () => (
    <div className='min-h-page'>
        <NewsElementGlobal />

        <Title>{language === 'pl' ? 'Mioty' : 'Litters'}</Title>

        <GetLittersElements />
    </div>
);

export default LittersView;
