import Title from 'components/Atoms/Title/Title';
import React from 'react';
import SimpleReactLightbox , { SRLWrapper } from 'simple-react-lightbox';
import './BreedElement.scss';

export default class BreedElement extends React.Component{

    toggleContent(){
        document.getElementById('content-container-'+this.props.id).classList.toggle('closed');
        document.getElementById('content-container-'+this.props.id).classList.toggle('opened');
    }
    
    render(){

        return(
            <div className="w-3/4 mx-auto mt-5 relative overflow-hidden">

                {/* TITLE */}
                <div onClick={this.toggleContent.bind(this)} className="w-1/1 h-12 flex items-center py-1 px-6 cursor-pointer bg-black bg-opacity-20 hover:bg-opacity-25">
                    <Title as={Title} size="30px">{this.props.title}</Title>
                </div>

                {/* BODY CONTENT */}
                <div className="w-1/1 bg-black bg-opacity-20 closed" id={`content-container-` + this.props.id}>
                    <div dangerouslySetInnerHTML={{__html: this.props.content}} className="p-6"></div>

                    {/* GALLERY */}
                    <div className="w-1/1 px-10 py-4">
                        <SimpleReactLightbox>
                            <SRLWrapper>
                                <div className="flex flex-wrap justify-center items-center">
                                    {this.props.gallery.map((element, index) => (
                                        <div className="m-2" key={index}>
                                            <a href={element.pathurl}>
                                                <img src={element.pathurl} alt="" className="h-32 w-auto rounded-md filter hover:brightness-125 transition-all m-0" />
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </SRLWrapper>
                        </SimpleReactLightbox>
                    </div>
                </div>
            </div>
        );
    }
}