import React from 'react';
import styled from 'styled-components';
import Title from 'components/Atoms/Title/Title';
import GetBreedElements from 'components/Organisms/GetBreedElements/GetBreedElements';
import cookies from 'react-cookies';

const language = cookies.load('language');

const Content = styled.div`
    margin: 50px 0;
`;

const BreedView = () => (
    <div className="min-h-page">
        <Title>{language === "pl" ? "O rasie" : "About Breed"} </Title>
        <Title size="30px">{language === "pl" ? "Setery Irlandzkie Czerwono-Białe" : "Irish Red And White Setters"} </Title>

        <Content>
            <GetBreedElements />
        </Content>
    </div>
);

export default BreedView;