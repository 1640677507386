import React from 'react';
import Title from 'components/Atoms/Title/Title';

const Pedigree = ({content}) => (
    <div className="w-11/12 xl:w-3/4 mx-auto">
        {content.length ? <Title size="33px">Rodowód</Title> : ""}
        <img src={content.length ? content[0].pathurl : ""} alt="" className="w-1/1 xl:w-5/6 mx-auto my-2 rounded-md"/>
    </div>
);

export default Pedigree;
