import React from 'react';
import Logo from 'components/Atoms/Logo/Logo';
import { Link, NavLink } from 'react-router-dom';
import cookies from 'react-cookies';
import poland from '../../../assets/images/poland.svg';
import england from '../../../assets/images/england.svg';
import './Header.scss';

class Header extends React.Component {
    setPL() {
        cookies.remove('language');
        cookies.save('language', 'pl', { path: '/' });
        window.location.reload();
    }

    setEN() {
        cookies.remove('language');
        cookies.save('language', 'en', { path: '/' });
        window.location.reload();
    }

    toggleMenu() {
        document.getElementById('menu').classList.toggle('left-0');
        document.getElementById('menu').classList.toggle('-left-full');
        document.getElementById('menuButton').classList.toggle('active');
    }

    render() {
        const language = cookies.load('language');

        return (
            <>
                <header className='w-1/1 flex justify-between items-center px-10 py-5 relative header'>
                    <Link to='/' className='hidden xl:flex'>
                        <Logo />
                    </Link>

                    <div
                        className='flex xl:justify-end justify-around items-center text-lg font-bold w-1/5 min-w-200px h-screen fixed top-0 -left-full xl:left-0 bg-gray-700 flex-col mx-auto xl:relative xl:w-3/4 xl:h-auto xl:flex-row xl:mx-0 xl:bg-opacity-0 transition-all duration-500'
                        id='menu'
                    >
                        <nav className='flex flex-col xl:flex-row'>
                            <NavLink
                                exact
                                to='/home'
                                onClick={this.toggleMenu.bind(this)}
                                className='no-underline text-yellow-100 mx-3 hover:text-link transition duration-300 ease-in-out'
                                activeClassName='text-link'
                            >
                                {' '}
                                {language === 'pl'
                                    ? 'Strona Główna'
                                    : 'Main Page'}{' '}
                            </NavLink>
                            <NavLink
                                to='/news'
                                onClick={this.toggleMenu.bind(this)}
                                className='no-underline text-yellow-100 mx-3 hover:text-link transition duration-300 ease-in-out'
                                activeClassName='text-link'
                            >
                                {' '}
                                {language === 'pl' ? 'Nowości' : 'News'}{' '}
                            </NavLink>
                            <NavLink
                                to='/dogs'
                                onClick={this.toggleMenu.bind(this)}
                                className='no-underline text-yellow-100 mx-3 hover:text-link transition duration-300 ease-in-out relative'
                                activeClassName='text-link'
                            >
                                {' '}
                                {language === 'pl'
                                    ? 'Nasze Psy'
                                    : 'Our Dogs'}{' '}
                            </NavLink>
                            <NavLink
                                to='/litters'
                                onClick={this.toggleMenu.bind(this)}
                                className='no-underline text-yellow-100 mx-3 hover:text-link transition duration-300 ease-in-out'
                                activeClassName='text-link'
                            >
                                {' '}
                                {language === 'pl'
                                    ? 'Szczenięta'
                                    : 'Puppies'}{' '}
                            </NavLink>
                            <NavLink
                                to='/about-us'
                                onClick={this.toggleMenu.bind(this)}
                                className='no-underline text-yellow-100 mx-3 hover:text-link transition duration-300 ease-in-out'
                                activeClassName='text-link'
                            >
                                {' '}
                                {language === 'pl' ? 'O Nas' : 'About Us'}{' '}
                            </NavLink>
                            <NavLink
                                to='/breed'
                                onClick={this.toggleMenu.bind(this)}
                                className='no-underline text-yellow-100 mx-3 hover:text-link transition duration-300 ease-in-out'
                                activeClassName='text-link'
                            >
                                {' '}
                                {language === 'pl' ? 'Rasa' : 'Breed'}{' '}
                            </NavLink>
                            <NavLink
                                to='/contact'
                                onClick={this.toggleMenu.bind(this)}
                                className='no-underline text-yellow-100 mx-3 hover:text-link transition duration-300 ease-in-out'
                                activeClassName='text-link'
                            >
                                {' '}
                                {language === 'pl' ? 'Kontakt' : 'Contact'}{' '}
                            </NavLink>
                        </nav>
                        <div className='flex justify-center items-center xl:ml-4 mx-auto w-1/1 xl:mx-0 xl-w-auto'>
                            <img
                                alt=''
                                src={poland}
                                className='h-10 w-auto cursor-pointer mx-2'
                                onClick={this.setPL.bind(this)}
                                title={
                                    language === 'pl'
                                        ? 'Zmień język na Polski'
                                        : 'Change Language to English'
                                }
                            />
                            <img
                                alt=''
                                src={england}
                                className='h-10 w-auto cursor-pointer mx-2'
                                onClick={this.setEN.bind(this)}
                                title={
                                    language === 'pl'
                                        ? 'Change Language to Polish'
                                        : 'Zmień język na Angielski'
                                }
                            />
                        </div>
                    </div>
                    <div
                        className='xl:hidden flex flex-col justify-around items-center w-8 h-7 fixed top-4 left-4 cursor-pointer hamburger'
                        onClick={this.toggleMenu.bind(this)}
                        id='menuButton'
                    >
                        <div className='hamburger1'></div>
                        <div className='hamburger2'></div>
                        <div className='hamburger3'></div>
                    </div>
                </header>
            </>
        );
    }
}

export default Header;
