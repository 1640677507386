import Title from 'components/Atoms/Title/Title';
import ReproductorsLink from 'components/Molecules/ReproductorsLink/ReproductorsLink';
import GetDogsElements from 'components/Organisms/GetDogsElements/GetDogsElements';
import React from 'react';
import cookies from 'react-cookies';

const language = cookies.load('language');

const DogsView = () => (
    <div className="min-h-page relative">
        <Title>{language === "pl" ? "Nasze psy" : "Our Dogs"}</Title>

        <GetDogsElements />

        <ReproductorsLink />
    </div>
);

export default DogsView;