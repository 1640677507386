import NewsElement from 'components/Molecules/NewsElement/NewsElement';
import React from 'react';
import axios from 'axios';
import cookies from 'react-cookies';
import Loading from 'components/Atoms/Loading/Loading';
import Title from '../../Atoms/Title/Title';

export default class GetNewsElements extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            items: [],
        };
    }

    componentDidMount() {
        this.getNews(2022);
    }

    getNews(year) {
        this.setState({
            isLoading: false,
        });

        window.scrollTo({ top: 0, behavior: 'smooth' });

        axios
            .get(`https://api.shadowdog.eu/v1/news?_year=${year}`, {
                headers: { 'Content-Type': 'application/json' },
            })
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { error, isLoading, items } = this.state;
        const language = cookies.load('language');

        if (error) {
            return <span>Błąd: {error}</span>;
        } else if (!isLoading) {
            return <Loading />;
        } else {
            return (
                <>
                    {items
                        .filter((element) => element.isGlobal === 0)
                        .map((item) => (
                            <NewsElement
                                key={item.id}
                                title={
                                    language === 'pl'
                                        ? item.pl_title
                                        : item.en_title
                                }
                                content={
                                    language === 'pl'
                                        ? item.pl_description
                                        : item.en_description
                                }
                                gallery={
                                    item.gallery.length ? item.gallery : []
                                }
                            />
                        ))}

                    <div className='w-full xl:w-3/4 px-5 py-3 mx-auto'>
                        <Title>
                            {language === 'pl' ? 'Archiwum' : 'Archive'}
                        </Title>
                        <div className='flex flex-col w-full justify-center items-center'>
                            <button
                                className='w-1/12 bg-black bg-opacity-20 rounded-xl px-3 py-1 my-2 transform hover:scale-110 duration-300'
                                onClick={() => this.getNews(2022)}
                            >
                                2022
                            </button>
                            <button
                                className='w-1/12 bg-black bg-opacity-20 rounded-xl px-3 py-1 my-2 transform hover:scale-110 duration-300'
                                onClick={() => this.getNews(2021)}
                            >
                                2021
                            </button>
                            <button
                                className='w-1/12 bg-black bg-opacity-20 rounded-xl px-3 py-1 my-2 transform hover:scale-110 duration-300'
                                onClick={() => this.getNews(2020)}
                            >
                                2020
                            </button>
                        </div>
                    </div>
                </>
            );
        }
    }
}
