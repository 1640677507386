import axios from 'axios';
import React from 'react';
import AboutusElement from 'components/Molecules/AboutusElement/AboutusElement';
import PropTypes from 'prop-types';
import cookies from 'react-cookies';
import Loading from 'components/Atoms/Loading/Loading';

export default class GetAboutusElements extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            items: [],
        }
    }

    componentDidMount(){
        axios.get('https://api.shadowdog.eu/v1/aboutus')
            .then(res => {
                this.setState({
                    isLoading: true,
                    items: res.data
                })
            })
            .catch(error => {
                this.setState({
                    isLoading: true,
                    error: error.message
                })
            })
    }

    render(){

        const { isLoading, error, items } = this.state;
        const language = cookies.load('language');

        if(error){
            return <span>Error: {error}</span>
        } else if(!isLoading){
            return <Loading />
        } else {
            return(
                items.map(item => (
                    <AboutusElement 
                        key={item.id}
                        title={ language === "pl" ? item.pl_title : item.en_title }
                        content={ language === "pl" ? item.pl_description : item.en_description}
                        gallery={item.gallery.length ? item.gallery : []}
                    />
                ))
            )
        }
    }
}

AboutusElement.PropTypes = {
    title: PropTypes.string,
    content: PropTypes.string.isRequired,
    gallery: PropTypes.array,
}

AboutusElement.defaultProps = {
    title: '',
    gallery: [],
}