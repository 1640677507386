import React from 'react';
import footerImage from '../../../assets/images/footer.png';
import './footer.scss';

const Footer = () => (
    <div
        className='w-1/1 hidden xl:flex justify-center items-center'
        id='footer'
    >
        <img
            src={footerImage}
            alt='footer'
            className='w-1/1 h-auto pointer-events-none -z-20'
        />
        <div className='absolute bottom-2 left-1/2 text-center footer-copy -z-20'>
            Copyright © 2000-{new Date().getFullYear()} Shadow Dog | Page
            created by{' '}
            <a href='https://www.facebook.com/WebDeveloperKrystianBudzisz'>
                Krystian Budzisz
            </a>
        </div>
    </div>
);

export default Footer;
