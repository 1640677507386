import React from 'react';
import { Link } from 'react-router-dom';
import Title from 'components/Atoms/Title/Title';
import litterFrame from '../../../assets/images/litter_frame.png';

const LitterElement = ({ id, title, birth, gallery }) => {
    const date = new Date(birth).getTime();
    const dateNow = new Date().getTime();
    const days = Math.floor((dateNow - date) / (1000 * 60 * 60 * 24));
    return (
        <Link
            className={`block relative w-80 transition-transform ease-out mx-10 my-10 no-underline hover:scale-110 `}
            to={{
                pathname: `litters/${id}`,
            }}
        >
            {days <= 120 ? (
                <div className='newLitter'>
                    <img src={litterFrame} alt='' />
                </div>
            ) : null}
            <img
                className='w-1/1 h-40 mx-auto my-0 no-underline rounded-2xl hover:rotate-3 transition:all duration-75 transform'
                src={
                    gallery
                        ? gallery
                              .filter((e) => e.category === 'mainImg')
                              .map((item) => item.pathurl)
                        : ''
                }
                title={title}
                alt={title}
            />
            <div>
                <Title size='23px'>
                    {title} ✦ {birth.slice(0, 10)}
                </Title>
            </div>
        </Link>
    );
};

export default LitterElement;
