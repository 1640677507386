import Title from 'components/Atoms/Title/Title';
import NewsElementGlobal from 'components/Molecules/NewsElementGlobal/NewsElementGlobal';
import GetNewsElements from 'components/Organisms/GetNewsElements/GetNewsElements';
import React from 'react';
import cookies from 'react-cookies';

export default class NewsView extends React.Component {

    render(){
        const language = cookies.load('language');

        return(
            <div className="min-h-page">
                <NewsElementGlobal />

                <Title>{language === "pl" ? "Nowości" : "News"}</Title>

                <GetNewsElements />

                
            </div>
        )
    }
}