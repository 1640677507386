import Parahraph from 'components/Atoms/Paragraph/Paragraph';
import Title from 'components/Atoms/Title/Title';
import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Loading from 'components/Atoms/Loading/Loading';
import cookies from 'react-cookies';

const StyledParentElement = styled.div`
    width: 100%;
    margin-top: 15px;
`;

export default class ParentElement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            error: null,
            isLoading: false,
        };
    }

    componentDidMount() {
        const id = this.props.id;
        console.log(id);

        axios
            .get(`https://api.shadowdog.eu/v1/pedigree/${id}`, {
                headers: { 'Content-Type': 'application/json' },
            })
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { items, isLoading, error } = this.state;
        const language = cookies.load('language');

        if (error) {
            return <span>Error: {error}</span>;
        } else if (!isLoading) {
            return <Loading />;
        } else {
            return (
                <div className='w-1/1'>
                    <Title size='30px' align='left'>
                        {items.name}
                    </Title>
                    <div
                        className='my-2'
                        dangerouslySetInnerHTML={{
                            __html:
                                language === 'pl'
                                    ? items.pl_description
                                    : items.en_description,
                        }}
                    ></div>

                    {/* HOVER EFFECT */}
                    <div className=''></div>
                </div>
            );
        }
    }
}
