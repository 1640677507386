import React from 'react';
import styled from 'styled-components';
import Title from 'components/Atoms/Title/Title';
import axios from 'axios';
import Loading from 'components/Atoms/Loading/Loading';
import cookies from 'react-cookies';
import './NewsElementGlobal.scss';

const TextContent = styled.div`
    width: 100%;
    height: 100%;

    ul {
        padding: 10px 30px;
    }
`;

const TitleStyle = styled.div`
    width: 100%;
    display: block;
    margin: 3px 5px 20px 5px;
`;

export default class NewsElementGlobal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            items: [],
        };
    }

    componentDidMount() {
        axios
            .get('https://api.shadowdog.eu/v1/news', {
                headers: { 'Content-Type': 'application/json' },
            })
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { error, items, isLoading } = this.state;
        const language = cookies.load('language');

        if (error) {
            return <span>Błąd: {error}</span>;
        } else if (!isLoading) {
            return <Loading />;
        } else {
            return items
                .filter((element) => element.isGlobal === 1)
                .map((item) => (
                    <div className='xl:bg-newsGlobal xl:w-full w-3/4 h-96 max-w-screen-2xl bg-cover bg-center bg-no-repeat my-0 mx-auto flex justify-start items-start flex-col overflow-hidden pl-0 xl:pl-40 pt-6 relative transp'>
                        <div class='w-1/1 xl:w-1/2 ml-0 mb-5'>
                            <Title size='40px' align='left'>
                                {language === 'pl'
                                    ? item.pl_title
                                    : item.en_title}
                            </Title>
                        </div>
                        <div
                            className='w-1/1 xl:w-1/2 ml-0 break-all'
                            dangerouslySetInnerHTML={{
                                __html:
                                    language === 'pl'
                                        ? item.pl_description
                                        : item.en_description,
                            }}
                        ></div>
                    </div>
                ));
        }
    }
}
