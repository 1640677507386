import React, { Component } from 'react';
import styled from 'styled-components'
import axios from 'axios';
import BreedElement from 'components/Molecules/BreedElement/BreedElement';
import cookies from 'react-cookies';
import Loading from 'components/Atoms/Loading/Loading';

const ErrorDiv = styled.div`
    width: 100%;
    text-align: center;
    color: red;
`;

export default class BreedView extends Component{
    constructor(props){
        super(props);
        this.state = {
            error: null,
            isLoading: false,
            items: []
        }
    }

    componentDidMount() {
        axios.get('https://api.shadowdog.eu/v1/breeds')
            .then(res => {
                this.setState({
                    isLoading: true,
                    items: res.data
                })
            })
            .catch(error => {
                this.setState({
                    isLoading: true,
                    error
                });
                console.log("error", error);
            })
    }

      render() {
        const { error, isLoading, items } = this.state;
        const language = cookies.load('language');

        if(error){
            return <ErrorDiv>Bład: {error.message}</ErrorDiv>;
        } else if(!isLoading){
            return <Loading />
        } else{
            return (
                <div>
                    {items.map(item => (
                        <BreedElement
                            key={item.id}
                            id={item.id}
                            title={language === "pl" ? item.pl_title : item.en_title}
                            content={language === "pl" ? item.pl_description : item.en_description}
                            gallery={item.gallery} />
                    ))}
                </div>
              );
        }
          
        }
}