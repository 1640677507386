import React from 'react';
import styled from 'styled-components';

const StyledParagraph = styled.p`
    color: ${ ({inputColor }) => inputColor === "primary" ? "#c1b29e" : "inherit"};
    margin-bottom: ${ ( {margin_B} ) => margin_B || 0};
`;

const Parahraph = ( {type, children, marginB} ) => (
    <StyledParagraph inputColor={type} margin_B={marginB}>{children}</StyledParagraph>
);

export default Parahraph;
