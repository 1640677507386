import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dogtemplate from 'assets/images/dogtemplate.svg';

const StyledImage = styled.img`
    transition: transform 0.5s ease-in-out;
    overflow: hidden;
    position: absolute;

    &:hover{
        transform: scale(1.1);
    }
`;

const DogElement = ({ id, title, gallery }) => {
    return(
        <div className="w-52 h-52 overflow-hidden rounded-full relative m-3">
            <Link to={{pathname: `dogs/${id}`}}>
                <StyledImage src={gallery ? gallery.filter( (item) => (item.category === "mainImg") ).map( (img) => ( img.pathurl ) ) : dogtemplate} title={title} alt={title}/>
            </Link>
        </div>
    );
};



export default DogElement;