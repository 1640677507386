import React from 'react';
import './MainPage.scss';

export default class MainPage extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            image: true,
        }
    }

    render(){
        return(
            <div className="w-1/1">
                <div className="absolute top-0 left-0 w-screen h-screen pointer-events-none -z-10 bg-phoneMainPage md:bg-mainPage bg-cover bg-center bg-no-repeat">
                    <div className="horizontal"></div>
                </div>
            </div>
        )
    }
}