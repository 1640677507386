import React from 'react';
import styled from 'styled-components';
import 'views/Root/GlobalStyles.module.scss';

const StyledSize = styled.span`
    font-size: ${props => props.size || '45px'};
    font-family: 'Alegreya SC', serif;
    display: block;
    background: -webkit-linear-gradient(#a59978, #d4b4a2, #e0dec7, #99662f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: ${props => props.align || 'center'};
`;

const Title = (props)=> (
    <StyledSize size={props.size} align={props.align}>{props.children}</StyledSize>
);

export default Title;