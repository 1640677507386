import React from 'react';
import styled from 'styled-components';
import MapNavigation from 'assets/images/mapnavigation.jpg';

const MapLocationStyle = styled.div`
    width: 80%;
    min-height: 350px;
    background-image: url(${MapNavigation});
    background-size: cover;
    background-position: center;
    margin: 0 auto 30px auto;
    border-radius: 20px;
`;

const MapLocation = () => (
    <MapLocationStyle/>
);

export default MapLocation;
