import Title from 'components/Atoms/Title/Title';
import GetAboutusElements from 'components/Organisms/GetAboutusElements/GetAboutusElements';
import React from 'react';
import cookies from 'react-cookies';

const language = cookies.load('language');

const AboutusView = () => (
    <div className="min-h-page">
        <Title>{language === "pl" ? "O nas" : "About Us"}</Title>

        <GetAboutusElements />
    </div>
);

export default AboutusView;